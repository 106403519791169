<template>
  <router-view :key="$route.path"/>
</template>

<script>
import Res from "@/helpers/Res";
import store from "@/store/store";

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Intera.BG',
    // all titles will be injected into this template
    titleTemplate: '%s | Digital Agency'
  },
  data() {
      return {
          resizeListener: null
      }
  },
  mounted() {
    // window.addEventListener('resize', this.setDimensions);
  },
  unmounted() {
    // window.removeEventListener('resize', this.setDimensions);
  },
  methods: {
    setDimensions() {
      store.commit('setWindowWidth', document.documentElement.clientWidth);
      store.commit('decProductCount', document.documentElement.clientHeight);
      store.commit('decProductCount', Res.isMobile());
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/variables.scss";

  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }

  .mt-6 {
    margin-top:4rem!important
  }
  .mt-7 {
    margin-top:5rem!important
  }
  .pt-7 {
    padding-top:5rem!important
  }
  body {
    font-family: $font-regular;
  }

  #app {
    font-family: $font-regular;
    color: #2c3e50;
  }

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.container-fluid {

}

.svg-icon {
  width: 2em;
  height: 2em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4c4d4f;
}

.svg-icon circle {
  stroke: #4c4d4f;
  stroke-width: 1;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
</style>
