import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'Page',
        meta: {transition: 'slide-left'},
        component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes: routes
})
//
// router.beforeEach((to, from, next) => {
//     setTimeout(()=>{
//         next () // will change route
//     }, 2000);
// })


export default router
