import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "./store/store"
import VueCookies from 'vue-cookies'

const app = createApp(App);
app.use(router, VueAxios, axios, store, VueCookies).mount('#app')
app.config.globalProperties.axios=axios