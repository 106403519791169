import { createStore } from "vuex"

const store = createStore({
    state:{
        windowWidth: null,
        windowHeight: null,
        isMobile: false,
    },
    mutations: {
        setWindowWidth (state, value) {
            state.windowWidth = value;
        },
        setWindowHeight (state, value) {
            state.windowHeight = value;
        },
        setIsMobile (state, value) {
            state.isMobile = value;
        },
    },
    getters: {
        windowWidth: state => {
            return state.windowWidth;
        },
        windowHeight: state => {
            return state.windowHeight;
        },
        isMobile: state => {
            return state.isMobile;
        },
    }
})

export default store